import * as React from "react"
import type { HeadFC, PageProps } from "gatsby"
import Layout from "@components/layout";
import {graphql, useStaticQuery} from "gatsby";
const ServicesPage: React.FC<PageProps> = (params: any) => {

    return (
        <Layout>
            <main>
                {/*<CommonPageHeader title="Oferta" subtitle="Oferta" />*/}
                <section className="contact-area pt-120 pb-90" data-background="assets/img/bg/bg-map.png">
                    <div className="container">
                        <div className="row">
                            <div className={"col-12"}>

                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </Layout>
    )
}

export default ServicesPage


export const Head: HeadFC = () => <title>Oferta - Fizjoproject Kiekrz</title>
